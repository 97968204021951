import { BaseApi } from './_base.api'
import { ENDPOINTS, NEW_ENDPOINTS } from '@constants'
import {
  ICompany,
  ICompanyClient,
  ICompanyInvoice,
  ICompanyTask,
  ISingleCompany,
} from '@typings'
import { AxiosResponse } from 'axios'

export class CompanyApi extends BaseApi {
  createCompany = (data: Partial<ICompany>) =>
    this.api.post(ENDPOINTS.COMPANY.CREATE, data).then(res => res)

  getMyCompany = () =>
    this.api
      .get(NEW_ENDPOINTS.COMPANIES.LIST)
      .then((res: AxiosResponse<{ data: ISingleCompany[] }>) => res.data.data)

  update = (id: ISingleCompany['id'], data: ISingleCompany) =>
    this.api
      .put(NEW_ENDPOINTS.COMPANIES.SINGLE(id), data)
      .then(res => res.data.data)

  delete = (id: ISingleCompany['id']) =>
    this.api
      .delete(NEW_ENDPOINTS.COMPANIES.SINGLE(id))
      .then(res => res)

  getSingle = (id: ISingleCompany['id']) =>
    this.api.get(NEW_ENDPOINTS.COMPANIES.SINGLE(id)).then(res => res.data.data)

  createTask = (data: ICompanyTask) =>
    this.api.post(ENDPOINTS.COMPANY.TASKS.CREATE, data).then(res => res.data)

  createInvoiceTask = (invoiceId: ICompanyInvoice['id'], data: ICompanyTask) =>
    this.api
      .post(ENDPOINTS.COMPANY.INVOICES.ADD_TASK(invoiceId), data)
      .then(res => res.data)

  getAllTasks = () =>
    this.api.get(ENDPOINTS.COMPANY.TASKS.ALL).then(res => res.data)

  createBankAccount = (data: any) =>
    this.api
      .post(ENDPOINTS.COMPANY.BANK_ACCOUNT.CREATE, data)
      .then(res => res.data)

  getAllBankAccount = () =>
    this.api.get(ENDPOINTS.COMPANY.BANK_ACCOUNT.ALL).then(res => res.data)

  createEmptyInvoice = () =>
    this.api.post(ENDPOINTS.COMPANY.INVOICES.CREATE_EMPTY).then(res => res.data)

  createInvoice = (data: any) =>
    this.api.post(ENDPOINTS.COMPANY.INVOICES.CREATE, data).then(res => res.data)

  getSingleInvoice = (id: ICompanyInvoice['id']) =>
    this.api.get(ENDPOINTS.COMPANY.INVOICES.SINGLE(id)).then(res => res.data)

  getAllInvoice = () =>
    this.api
      .get(ENDPOINTS.COMPANY.INVOICES.ALL, {
        params: {
          datagrid: true,
        },
      })
      .then(res => res.data)

  createClient = (data: ICompanyClient) =>
    this.api.post(ENDPOINTS.COMPANY.CLIENTS.CREATE, data).then(res => res)
  getAllClient = (datagrid = false) => {
    let params = {}
    if (datagrid) {
      params = {
        datagrid,
      }
    }

    return this.api
      .get(ENDPOINTS.COMPANY.CLIENTS.ALL, {
        params,
      })
      .then(res => res.data)
  }
}
