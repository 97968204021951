import { OvErrorBoundary, OvModal, RequireAuth } from '@components'
import { URL_PATHS } from '@constants'
import { observer } from 'mobx-react-lite'
import {
  Navigate,
  Outlet,
  ScrollRestoration,
  useLocation,
} from 'react-router-dom'

export const DefaultWithoutMenu = observer(() => {
  const location = useLocation()

  return (
    <RequireAuth>
      <>
        <ScrollRestoration />
        <main>
          <OvErrorBoundary>
            <Outlet />
          </OvErrorBoundary>
        </main>
        <OvModal />
      </>
    </RequireAuth>
  )
})
