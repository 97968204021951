import { OvPoliciesHoc } from '@components'
import { URL_PATHS } from '@constants'
import { useStore } from '@hooks/useStore'
import { observer } from 'mobx-react-lite'
import { Navigate, useLocation } from 'react-router-dom'

interface IRequireAuth {
  children: JSX.Element
  allowed?: string[]
}

export const RequireAuth = observer(({ children }: IRequireAuth) => {
  const { auth } = useStore()
  const location = useLocation()

  if (!auth.is_authenticated) {
    return (
      <Navigate
        to={URL_PATHS.LOGIN}
        // state={{ intended: location.pathname }}
      />
    )
  }

  return <OvPoliciesHoc>{children}</OvPoliciesHoc>
})
