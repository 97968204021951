import { OvForm, OvFormInput, OvFormProvider, PageWrapper } from '@components'
import { KEYS, LABELS, URL_PATHS } from '@constants'
import { getFromStorage } from '@helpers/storage.helpers'
import { useIsMobile } from '@hooks/useIsMobile'
import { useStore } from '@hooks/useStore'
import { ArrowBack, ArrowForward, Logout } from '@mui/icons-material'
import { Box, Button, Collapse, MobileStepper, Paper, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { MY_COMPANY_DEFAULT_VALUES } from 'src/constants/forms/my-company/my-company.defaultvalues.constants'
import { WelcomeOnboarding } from 'src/partials/index.partials'

export const OnboardingView = observer(() => {
  const { user, company, auth } = useStore()
  const isMobile = useIsMobile()
  const [activeStep, setActiveStep] = useState(0)
  const navigate = useNavigate()
  const validationSteps = [[], ['profile.name', 'profile.email'], ['company.name', 'company.coc']]

  const has_company = getFromStorage(KEYS.HAS_COMPANY)

  const form = useForm({
    defaultValues: {
      profile: { ...user.current_user },
      company: MY_COMPANY_DEFAULT_VALUES,
    },
    reValidateMode: 'onChange',
  })

  const { watch, trigger, getValues, setValue } = form

  const ProfileForm = () => (
    <>
      <Stack spacing={2}>
        <OvFormInput
          name="profile.name"
          label={LABELS.NAME}
          required
          rules={{ required: `${LABELS.NAME} is verplicht` }}
        />
        <OvFormInput
          name="profile.email"
          type="email"
          disabled
          required
          label={LABELS.EMAIL}
          rules={{ required: `${LABELS.EMAIL} is verplicht` }}
        />
      </Stack>
    </>
  )

  const CompanyInfoForm = () => {
    const tax_payable = watch('company.tax_payable')
    return (
      <>
        <Stack spacing={2}>
          <OvFormInput
            name="company.name"
            label={LABELS.NAME}
            rules={{ required: `${LABELS.NAME} is verplicht` }}
            required
          />
          <OvFormInput
            name="company.coc"
            label={LABELS.COC}
            required
            rules={{ required: `${LABELS.COC} is verplicht` }}
          />
          <OvFormInput
            type="checkbox"
            name="company.tax_payable"
            label="BTW plichtig"
          />
          <Collapse in={tax_payable}>
            <OvFormInput
              required={tax_payable}
              name="company.tax"
              label={LABELS.TAX_NUMBER}
            />
          </Collapse>
        </Stack>
      </>
    )
  }

  const OnboardingOverview = () => {
    const taxPayable = getValues('company.tax_payable')
    if (!taxPayable) {
      setValue('company.tax', null)
    }
    return (
      <Stack spacing={2}>
        <OvFormInput
          name="profile.name"
          label={LABELS.NAME}
        />
        <OvFormInput
          name="profile.email"
          type="email"
          label={LABELS.EMAIL}
        />
        <OvFormInput
          name="company.name"
          label={LABELS.NAME}
          required
        />
        <OvFormInput
          name="company.coc"
          label={LABELS.COC}
          required
        />
        <OvFormInput
          type="checkbox"
          name="company.tax_payable"
          label="BTW plichtig?"
        />
        {taxPayable && (
          <OvFormInput
            name="company.tax"
            label={LABELS.TAX_NUMBER}
          />
        )}
      </Stack>
    )
  }

  const steps = [
    { title: 'Welkom', component: <WelcomeOnboarding /> },
    { title: 'Profiel', component: <ProfileForm /> },
    {
      title: 'Bedrijfsgegevens',
      component: <CompanyInfoForm />,
    },
    { title: 'Controle', component: <OnboardingOverview /> },
  ]

  const renderSteps = useMemo(() => {
    return steps.map((singleStep, i) => (
      <Step key={singleStep.title}>
        <StepLabel>{singleStep.title}</StepLabel>
      </Step>
    ))
  }, [steps])

  const handleNext = async (e: any) => {
    e.preventDefault()
    const valid = await trigger(validationSteps[activeStep] as any)
    if (valid) {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleFinish = async (data: any) => {
    const { profile, company: companyData } = data

    try {
      await user.update(profile)
      await company.create(companyData)
      navigate(URL_PATHS.DASHBOARD)
    } catch (e) {
      console.log('error: ', e)
      void 0
    }

    console.log('finished')
  }

  const onFormSubmit = () => {
    form.handleSubmit(handleFinish)()
  }

  const getPageTitle = useMemo(() => {
    return `Welcome in Outvoice, ${user.current_user_name}`
  }, [user.current_user_name])

  useEffect(() => {
    if (has_company) navigate(URL_PATHS.DASHBOARD)
  }, [has_company])

  return (
    <OvFormProvider
      form={form}
      readOnly={activeStep === steps.length - 1}
      onSubmit={onFormSubmit}>
      <PageWrapper
        beforeInner={
          <Stack
            component={Paper}
            elevation={3}
            spacing={3}
            sx={{ position: 'sticky', top: 0, py: isMobile ? 3 : 5, px: 2.5 }}>
            <Typography
              variant="h2"
              color="black">
              {getPageTitle}
            </Typography>
            {!isMobile && <Stepper activeStep={activeStep}>{renderSteps}</Stepper>}
          </Stack>
        }>
        <Box>
          <OvForm>
            <Box sx={{ display: 'flex', flexGrow: 1, flexShrink: 0, pb: 11, flexDirection: 'column' }}>
              <Stack spacing={2}>
                <Typography
                  variant="h2"
                  color="black">
                  {steps[activeStep].title}
                </Typography>
                {steps[activeStep].component}
              </Stack>
            </Box>
            {isMobile ? (
              <MobileStepper
                activeStep={activeStep}
                steps={steps.length}
                variant="dots"
                position="bottom"
                elevation={3}
                sx={{ background: 'white' }}
                backButton={
                  activeStep === 0 ? (
                    <Button
                      size="small"
                      color="inherit"
                      onClick={() => auth.handleLogOut()}
                      startIcon={<Logout />}>
                      {LABELS.LOGOUT}
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      startIcon={<ArrowBack />}>
                      {LABELS.BACK}
                    </Button>
                  )
                }
                nextButton={
                  activeStep !== steps.length - 1 ? (
                    <Button
                      size="small"
                      onClick={(e: any) => handleNext(e)}
                      variant="contained"
                      endIcon={<ArrowForward />}>
                      {LABELS.NEXT}
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained">
                      {LABELS.FINISH}
                    </Button>
                  )
                }
              />
            ) : (
              <Paper
                elevation={3}
                sx={({ palette }) => ({
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  p: 2.5,
                  background: palette.background.paper,
                })}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  {activeStep === 0 ? (
                    <Button
                      size="small"
                      color="inherit"
                      onClick={() => auth.handleLogOut()}
                      startIcon={<Logout />}>
                      {LABELS.LOGOUT}
                    </Button>
                  ) : (
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                      startIcon={<ArrowBack />}>
                      {LABELS.BACK}
                    </Button>
                  )}
                  <Box sx={{ flex: '1 1 auto' }}></Box>
                  {activeStep !== steps.length - 1 ? (
                    <Button
                      onClick={(e: any) => handleNext(e)}
                      variant="contained"
                      endIcon={<ArrowForward />}>
                      {LABELS.NEXT}
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained">
                      {LABELS.FINISH}
                    </Button>
                  )}
                </Box>
              </Paper>
            )}
          </OvForm>
        </Box>
      </PageWrapper>
    </OvFormProvider>
  )
})
