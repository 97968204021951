import { STORE_KEYS } from '@constants'
import { saveToStorage } from '@helpers/storage.helpers'
import { Store } from '@stores'
import { action, computed, makeAutoObservable, runInAction } from 'mobx'

type IPolicies = string[]

const defaultValues: Record<string, any> = {
  loading: true,
  policies: [],
}
export class PoliciesStore {
  [key: string]: any
  loading: boolean
  policies: IPolicies

  constructor(store: Store) {
    this.store = store
    this.loading = defaultValues.loading
    this.policies = []
    makeAutoObservable(this)
  }

  @computed
  get current_policies(): IPolicies {
    return this.policies
  }

  @action
  addPolicies = (policies: IPolicies) => {
    const current = this.policies

    const new_policies = new Set([...current, ...policies])
    runInAction(() => this.set('policies', Array.from(new_policies)))
  }

  @action
  removePolicies = (policies: IPolicies) => {
    const current = new Set(this.policies)
    for (const policy in policies) {
      current.delete(policy)
    }

    runInAction(() => this.set('policies', Array.from(current)))
  }

  @action
  can = (policy: string) => {
    const current = new Set(this.policies)
    return current.has(policy)
  }

  @action
  cannot = (policy: string) => {
    return !this.can(policy)
  }

  @action
  reset = (withLoading?: boolean) => {
    runInAction(() =>
      Object.keys(defaultValues).map(key => {
        this.set(key, defaultValues[key])
      })
    )
    if (withLoading) this.set(STORE_KEYS.LOADING, false)
  }

  @action
  set = (target: string, value: unknown, save?: boolean): unknown => {
    if (!target) return
    runInAction(() => (this[target] = value))
    if (save) saveToStorage(target, value)
  }
}
