import { PageWrapper } from '@components'
import { LABELS, URL_PATHS, WARNINGS } from '@constants'
import { useIsMobile } from '@hooks/useIsMobile'
import { useSetPageTitle } from '@hooks/useSetPageTitle'
import { useStore } from '@hooks/useStore'
import { Add, Search } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import { GridCallbackDetails, GridRow, GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid'
import { IUiModalGeneric } from '@typings'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { OvDataGrid } from 'src/components/core/ov-data-grid/ov-data-grid'
import { POLICIES } from 'src/constants/policies.constants'
import { FindCompanyForm, MobileClientRow } from 'src/partials/index.partials'

export const ClientsList = observer(() => {
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])
  const { client, ui, policies } = useStore()
  const isMobile = useIsMobile()
  const navigate = useNavigate()

  const handleRowClick = (params: GridRowParams) => {
    if (!params.id) return
    const path = generatePath(URL_PATHS.CLIENT_DETAIL, {
      id: params.id,
    })
    return navigate(path)
  }

  const canCreate = useMemo(() => {
    return policies.can(POLICIES.CLIENT.CREATE)
  }, [policies.current_policies])

  const newClientModalOptions: Partial<IUiModalGeneric> = {
    title: 'Zoek in KVK',
    body: () => <FindCompanyForm callBack={row => client.createClient(row)} />,
    modalOptions: {
      fullWidth: true,
    },
  }

  const handleRowSelection = (model: GridRowSelectionModel, details: GridCallbackDetails) => setRowSelectionModel(model)

  const handleCreateClient = () => {
    navigate(URL_PATHS.CLIENT_CREATE)
  }

  const handleSearchInKvk = async () => {
    await ui.showModal(newClientModalOptions).catch(() => void 0)
  }

  const CustomRow = useCallback(
    (props: any) =>
      isMobile ? (
        <MobileClientRow
          {...props.row}
          onRowClick={handleRowClick}
        />
      ) : (
        <GridRow {...props} />
      ),
    [isMobile]
  )

  useEffect(() => {
    return () => client.reset()
  }, [])

  return (
    <PageWrapper
      pageTitle={LABELS.CLIENTS}
      allowed={policies.can(POLICIES.CLIENT.READ)}
      allowedMessage={WARNINGS.NOT_ALLOWED.NO_COMPANY}
      actions={
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            width: { sm: '100%', md: 'initial' },
          }}>
          <Button
            endIcon={<Add />}
            disabled={!canCreate}
            variant="contained"
            onClick={handleCreateClient}>
            Nieuw
          </Button>
        </Box>
      }>
      <OvDataGrid
        onRowClick={handleRowClick}
        rowSelectionModel={rowSelectionModel}
        store={client.data_grid}
        slots={{
          row: CustomRow,
        }}
        withToolBar
        onRowSelectionModelChange={(model, details) => setRowSelectionModel(model)}
        responsiveColumns
      />
    </PageWrapper>
  )
})
