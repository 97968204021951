import { KEYS, URL_PATHS } from '@constants'
import { getFromStorage } from '@helpers/storage.helpers'
import { useStore } from '@hooks/useStore'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { redirect, useNavigate } from 'react-router-dom'
import { POLICIES } from 'src/constants/policies.constants'

export interface IPolicyHocProps {
  children: JSX.Element
}

export const OvPoliciesHoc = observer(({ children }: IPolicyHocProps) => {
  const { company, policies } = useStore()
  const navigate = useNavigate()

  // SET COMPANY POLICIES
  useEffect(() => {
    const hasCompany = getFromStorage(KEYS.HAS_COMPANY) || company.has_company
    const hasNoCompanyPolicies = [POLICIES.COMPANY.CREATE]
    const hasCompanyPolicies = [
      POLICIES.CLIENT.READ,
      POLICIES.CLIENT.CREATE,
      POLICIES.CLIENT.UPDATE,
      POLICIES.CLIENT.DELETE,
      POLICIES.DOCUMENT.CREATE,
      POLICIES.DOCUMENT.READ,
      POLICIES.DOCUMENT.UPDATE,
      POLICIES.DOCUMENT.DELETE,
      POLICIES.COMPANY.DELETE,
      POLICIES.COMPANY.READ,
      POLICIES.COMPANY.UPDATE,
    ]
    if (hasCompany) {
      policies.addPolicies(hasCompanyPolicies)
    }

    if (!hasCompany) {
      policies.removePolicies(hasCompanyPolicies)
      policies.addPolicies(hasNoCompanyPolicies)
      navigate(URL_PATHS.WELCOME)
    }
  }, [company.has_company])

  useEffect(() => {
    return () => policies.reset()
  }, [])

  return <>{children}</>
})
