export const POLICIES = {
  COMPANY: {
    CREATE: 'company.create',
    READ: 'company.read',
    UPDATE: 'company.update',
    DELETE: 'company.delete',
  },
  DOCUMENT: {
    CREATE: 'document.create',
    READ: 'document.read',
    UPDATE: 'document.update',
    DELETE: 'document.delete',
  },
  CLIENT: {
    CREATE: 'client.create',
    READ: 'client.read',
    UPDATE: 'client.update',
    DELETE: 'client.delete',
  },
}
