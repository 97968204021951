import { LABELS, MAIN_MY_NAV, MAIN_PAGE_NAV, URL_PATHS } from '@constants'
import { useIsMobile } from '@hooks/useIsMobile'
import { useStore } from '@hooks/useStore'
import { ChevronLeft, ChevronRight, Logout, Menu } from '@mui/icons-material'
import InvoiceIcon from '@mui/icons-material/ReceiptLong'
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { ForwardedRef, useCallback, useMemo } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import styles from './outvoice-menu-drawer.module.scss'

export const MenuDrawer = observer(() => {
  const { ui, auth, policies } = useStore()
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const location = useLocation()

  const handleNavLinkClick = () => {
    if (ui.menu_open) {
      ui.toggleMenu()
    }
  }

  const NavItem = useCallback(
    (
      {
        label,
        path,
        icon: Icon,
        policy,
      }: {
        label: string
        path: string
        icon: typeof InvoiceIcon
        badge?: boolean
        policy?: string
      },
      ref: ForwardedRef<HTMLAnchorElement>
    ) => {
      return policy && policies.cannot(policy) ? (
        <Box>
          <Tooltip
            title={label}
            placement="right">
            <ListItemButton
              disabled={true}
              divider
              className={clsx(styles['ov-drawer-list-button'], !ui.menuOpen && styles['ov-drawer-list-button-min'])}>
              <ListItemIcon>
                <Icon className={styles['ov-drawer-list-icon']} />
              </ListItemIcon>
              <ListItemText className={styles['ov-drawer-list-label']}>{label}</ListItemText>
            </ListItemButton>
          </Tooltip>
        </Box>
      ) : (
        <NavLink
          to={path}
          ref={ref}
          aria-disabled={policy ? !policies.can(policy) : false}
          onClick={handleNavLinkClick}>
          {({ isActive }) => (
            <Tooltip
              title={label}
              placement="right">
              <ListItemButton
                disabled={policy ? !policies.can(policy) : false}
                divider
                selected={isActive}
                className={clsx(
                  styles['ov-drawer-list-button'],
                  isActive && styles['ov-drawer-list-button--active'],
                  !ui.menuOpen && styles['ov-drawer-list-button-min']
                )}>
                <ListItemIcon>
                  <Icon className={styles['ov-drawer-list-icon']} />
                </ListItemIcon>

                <ListItemText className={styles['ov-drawer-list-label']}>{label}</ListItemText>
              </ListItemButton>
            </Tooltip>
          )}
        </NavLink>
      )
    },
    [policies.current_policies]
  )

  const renderMenuItems = useMemo(() => {
    return (
      <>
        <List>
          <ListItemButton onClick={() => ui.toggleMenu()}>
            <ListItemIcon>
              {ui.menuOpen ? (
                <ChevronLeft className={styles['ov-drawer-list-icon']} />
              ) : (
                <ChevronRight className={styles['ov-drawer-list-icon']} />
              )}
            </ListItemIcon>
          </ListItemButton>
          <Divider
            sx={theme => ({
              borderColor: theme.palette.primary.contrastText,
              opacity: 0.4,
            })}
          />
          {MAIN_PAGE_NAV.map(singleItem => (
            <NavItem
              {...singleItem}
              key={singleItem.label}
            />
          ))}
        </List>
      </>
    )
  }, [ui.menuOpen, policies.current_policies])

  const renderMyMenu = useMemo(() => {
    return (
      <List>
        <ListItemButton onClick={() => ui.toggleMenu()}>
          <ListItemIcon>
            {ui.menuOpen ? (
              <ChevronLeft className={styles['ov-drawer-list-icon']} />
            ) : (
              <ChevronRight className={styles['ov-drawer-list-icon']} />
            )}
          </ListItemIcon>
        </ListItemButton>
        <Divider
          sx={theme => ({
            borderColor: theme.palette.primary.contrastText,
            opacity: 0.4,
          })}
        />
        {MAIN_MY_NAV.map(singleItem => (
          <NavItem
            {...singleItem}
            key={singleItem.label}
          />
        ))}
        <Divider
          sx={theme => ({
            borderColor: theme.palette.primary.contrastText,
            opacity: 0.4,
          })}
        />
        <Tooltip
          title={LABELS.LOGOUT}
          placement="right"
          hidden={ui.menuOpen}>
          <ListItemButton
            onClick={() => auth.handleLogOut()}
            className={clsx(styles['ov-drawer-list-button'], !ui.menuOpen && styles['ov-drawer-list-button-min'])}>
            <ListItemIcon>
              <Logout className={styles['ov-drawer-list-icon']} />
            </ListItemIcon>
            <ListItemText>{LABELS.LOGOUT}</ListItemText>
          </ListItemButton>
        </Tooltip>
      </List>
    )
  }, [ui.menuOpen, policies.current_policies])

  const getPaperClasses = useMemo(
    () => clsx(styles['ov-drawer'], ui.menuOpen && styles['ov-drawer--open']),
    [ui.menuOpen]
  )

  const currentLocation = useMemo(() => location.pathname, [location.pathname])

  return (
    <>
      <Box className={styles['ov-drawer-logo']}>
        <Typography
          onClick={() => navigate(URL_PATHS.DASHBOARD)}
          variant={'h4'}
          sx={theme => ({ fontSize: theme.spacing(2) })}>
          {LABELS.OUTVOICE}
        </Typography>
      </Box>
      {isMobile ? (
        <Paper
          sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
          elevation={3}>
          <BottomNavigation
            showLabels
            value={currentLocation}>
            {MAIN_PAGE_NAV.map(({ label, path, icon: Icon }) => (
              <BottomNavigationAction
                label={label}
                href={path}
                icon={<Icon />}
                key={label}
                value={path}
              />
            ))}
            <BottomNavigationAction
              label="Instellingen"
              icon={<Menu />}
            />
          </BottomNavigation>
        </Paper>
      ) : (
        <Drawer
          PaperProps={{
            className: getPaperClasses,
          }}
          variant="permanent"
          onClose={() => ui.toggleMenu()}>
          <Box
            sx={theme => ({
              width: '20rem',
              height: '100%',
              paddingTop: '56px',
              color: theme.palette.primary.contrastText,
              fill: theme.palette.primary.contrastText,
            })}>
            {renderMenuItems}
          </Box>
          <Box sx={{ width: '20rem' }}>{renderMyMenu}</Box>
        </Drawer>
      )}
    </>
  )
})
