import { OvCard, OvForm, OvFormInput } from '@components'
import { LABELS } from '@constants'
import { InputAdornment, Stack } from '@mui/material'
import { ISingleCompany } from '@typings'
import { observer } from 'mobx-react-lite'

export const MyCompanyFormInvoice = observer(() => {
  const FormInput = OvFormInput<ISingleCompany>
  return (
    <OvForm>
      <OvCard>
        <Stack
          direction="column"
          gap={2}>
          <FormInput
            type="currency"
            label={LABELS.PRICE_PER_HOUR}
            name="hourly_rate"
          />
          <FormInput
            label={LABELS.EXPIRES_AFTER}
            name="invoice_expires_after"
            inputProps={{
              type: 'number',
              min: 0,
              step: 1,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">dagen</InputAdornment>
              ),
            }}
          />
          <FormInput
            label={LABELS.QUOTATION_EXPIRES_AFTER}
            name="quotation_expires_after"
            inputProps={{
              type: 'number',
              min: 0,
              step: 1,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">dagen</InputAdornment>
              ),
            }}
          />
        </Stack>
      </OvCard>
    </OvForm>
  )
})
