/* eslint no-use-before-define: 0 */
export const LABELS = {
  BACK: 'Terug',
  CITY: 'Stad',
  CLIENT: 'Klant',
  CLIENT_CREATE: 'Nieuwe klant',
  CLIENTS: 'Klanten',
  CLIENT_NAME: 'Klantnaam',
  COC: 'KVK nummer',
  CONTINUE: 'Ga door',
  CONFIRM_REMOVE_CONTACT_TITLE: 'Contactpersoon verwijderen?',
  CONFIRM_EXIT_PAGE_TITLE: 'Pagina verlaten?',
  CONFIRM_EXIT_PAGE_TEXT: `De wijzigingen zijn nog niet opgeslagen. <br />
  Weet je zeker dat je deze pagina wilt verlaten?
  `,
  COUNTRY: 'Land',
  DASHOARD: 'Dashboard',
  DESCRIPTION: 'Beschrijving',
  DATE: 'Datum',
  EMAIL: 'E-mail',
  FINISH: 'Afronden',
  HOUSE_NUMBER: 'Huisnummer',
  INVOICE: 'Factuur',
  INVOICES: 'Facturen',
  INVOICE_NUMBER: 'Factuur nummer',
  LOGIN: 'Inloggen',
  LOGOUT: 'Uitloggen',
  MY_OUTVOICE: 'Mijn Outvoice',
  MY_PROFILE: 'Mijn profiel',
  MY_COMPANY: 'Mijn onderneming',
  MY_ACCOUNT: 'Mijn account',
  NAME: 'Naam',
  NEXT: 'Volgende',
  OUTVOICE: 'Outvoice',
  PASSWORD: 'Wachtwoord',
  PASSWORD_AGAIN: 'Nogmaals wachtwoord',
  PASSWORD_FORGOT: 'Wachtwoord vergeten',
  PASSWORD_REQUIRED: 'Vul uw wachtwoord in',
  CONFIRM_PASSWORD_REQUIRED: 'Vul uw wachtwoord nogmaals in',
  CONFIRM_PASSWORD_DOES_NOT_MATCH: 'De wachtwoorden komen niet overeen',
  POSTAL_CODE: 'Postcode',
  PRICE: 'Prijs',
  PRICE_PER_HOUR: 'Uurprijs',
  EXPIRES_AFTER: 'Verloopt na',
  QUOTATION: 'Offerte',
  REFERENCE: 'Referentie',
  QUANTITY: 'Aantal',
  QUOTATION_REFERENCE: 'Offerte referentie',
  QUOTATION_EXPIRES_AFTER: 'Offerte verloopt na',
  QUOTES: 'Offertes',
  REGISTER: 'Registreer',
  REGISTER_FORM_TITLE: 'Registreer je gratis account',
  SEARCH: 'Zoeken',
  SUB_TOTAL: 'Totaal (ex BTW)',
  STREET: 'Straat',
  TAX_NUMBER: 'BTW nummer',
  TOTAL: 'Totaal (inc BTW)',
  UPDATED_AT: 'Laatst gewijzigd',
  VAT: 'BTW',
  USERNAME: 'Gebruikersnaam',
  USERNAME_REQUIRED: 'Vul uw gebruikersnaam in',
}

export const DYNAMIC_LABELS = {
  LOGGED_IN_AS: (user: string) => `Ingelogd als ${user}`,
  CONFIRM_REMOVE_CONTACT: (X: string) =>
    `Je staat op het punt om ${X} te verwijderen als contactpersoon. <br /> Weet je het zeker?`,
  INVOICE_NR: (nr: string) => `${LABELS.INVOICE} ${nr}`,
  QUOTATION_NR: (nr: string) => `${LABELS.QUOTATION} ${nr}`,
}

export const LABELS_ABBR = {
  INVOICE_NUMBER: 'Nr',
  TOTAL: 'Totaal',
  CLIENT_NAME: 'Naam',
  REF: 'Ref',
}

export const ERRORS = {
  LOGIN: {
    FAILED: 'Er is iets misgegaan met inloggen. Probeer het nogmaals.',
  },
  SIGNUP: {
    FAILED: 'Het is niet gelukt om je aan te melden. Probeer het nogmaals.',
  },
}

export const WARNINGS = {
  NOT_ALLOWED: {
    NO_COMPANY: 'Het bezoeken van deze pagina is (nog) niet toegestaan. Vul eerst je gegevens in bij mijn onderneming.',
  },
}

export const FORM_LABELS = {
  REQUIRED: (field: string) => `${field} is verplicht`,
  INVALID: (field: string) => `${field} ongeldig`,
}
