import { OvCard, OvForm, OvFormInput, OvFormProvider } from '@components'
import { useStore } from '@hooks/useStore'
import { Box, Button, Card, TextField } from '@mui/material'
import { IUser } from '@typings'
import { observer } from 'mobx-react-lite'
import { FieldValues, useForm } from 'react-hook-form'

export const ProfileForm = observer(() => {
  const { user } = useStore()

  const form = useForm({
    defaultValues: {
      // dateOfBirth: user.current_user?.profile?.dateOfBirth,
      name: user.current_user?.name,
      email: user.current_user?.email,
    },
  })

  const onSubmit = (values: FieldValues) => {
    console.log('values: ', values)
    user.update(values as IUser)
  }

  const FormInput = OvFormInput

  return (
    <OvForm>
      <OvCard>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <FormInput
            label="Naam"
            name="name"
            required
          />
          <FormInput
            label="Naam"
            name="email"
            required
          />
        </Box>
        <Button
          sx={{ marginTop: '1rem' }}
          variant="contained"
          type="submit">
          Opslaan
        </Button>
      </OvCard>
    </OvForm>
  )
})
