import { OvFormProvider, OvTabList, PageWrapper } from '@components'
import { LABELS, MY_COMPANY_TABS } from '@constants'
import { useStore } from '@hooks/useStore'
import { Delete, Error } from '@mui/icons-material'
import { TabContext, TabPanel } from '@mui/lab'
import { Alert, Box, Button, Collapse, FormHelperText, Stack, Tab } from '@mui/material'
import { IOVErrorResponseData, ISingleCompany } from '@typings'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { POLICIES } from 'src/constants/policies.constants'

export const MyCompanyView = observer(() => {
  const { company, policies } = useStore()
  const [selectedTab, setSelectedTab] = useState(MY_COMPANY_TABS[0].value)
  const [formError, setFormError] = useState<string | null>()

  const handleTabChange = (e: React.SyntheticEvent<Element, Event>, val: string) => setSelectedTab(val)

  const getDefaultValues = async () => {
    return await company.getMyCompany().catch(e => void 0)
  }

  const form = useForm({
    defaultValues: getDefaultValues,
  })

  const handleSubmit = () => {
    form.handleSubmit(onSubmit)()
  }

  const handleSucces = (data: ISingleCompany) => {
    toast('Bedrijfsggegevens opgeslaggen', { type: 'success' })
    form.reset(data)
  }

  const handleFormErrors = (data: IOVErrorResponseData) => {
    const { message, errors } = data

    if (message) setFormError(message)

    if (errors) {
      const errorKeys = Object.keys(errors)

      errorKeys.map(singleKey =>
        form.setError(singleKey as any, {
          type: 'custom',
          message: errors[singleKey][0],
        })
      )
    }
  }

  const onSubmit = async (values: FieldValues) => {
    setFormError(null)
    try {
      const res = company.has_company ? await company.update(values) : await company.create(values)
      handleSucces(res)
    } catch (e) {
      handleFormErrors(e as IOVErrorResponseData)
    }
  }

  const renderCompanyView = useMemo(() => {
    return (
      <TabContext value={selectedTab}>
        <OvTabList onChange={handleTabChange}>
          {MY_COMPANY_TABS.map(singleTab => (
            <Tab
              value={singleTab.value}
              label={singleTab.label}
              key={singleTab.value}
              disabled={singleTab.disabled}
            />
          ))}
        </OvTabList>
        <Box>
          {MY_COMPANY_TABS.map(singleTab => (
            <TabPanel
              sx={{ padding: '24px 0' }}
              value={singleTab.value}
              key={singleTab.value}>
              <Stack spacing={1}>
                <Collapse in={!!formError}>
                  <Alert
                    color="error"
                    icon={<Error />}>
                    <FormHelperText error>{formError}</FormHelperText>
                  </Alert>
                </Collapse>
                {singleTab.component ?? singleTab.label}
              </Stack>
            </TabPanel>
          ))}
        </Box>
      </TabContext>
    )
  }, [company.has_companies, company.current_company, selectedTab, formError])

  return (
    <OvFormProvider
      form={form}
      onSubmit={() => onSubmit}
      loading={company.loading}>
      <PageWrapper pageTitle={LABELS.MY_COMPANY}>
        {renderCompanyView}
        <Stack
          direction="row"
          gap={2}>
          <Button
            variant="outlined"
            type="reset"
            onClick={() => form.reset()}
            disabled={!form.formState.isDirty}>
            Annuleren
          </Button>
          <Button
            variant="contained"
            type="submit"
            onClick={handleSubmit}
            disabled={!form.formState.isDirty}>
            Opslaan
          </Button>
        </Stack>
      </PageWrapper>
    </OvFormProvider>
  )
})
