import { PageWrapper } from '@components'
import { DYNAMIC_LABELS, LABELS } from '@constants'
import { useIsMobile } from '@hooks/useIsMobile'
import { useStore } from '@hooks/useStore'
import { Check, Download, Edit, Save } from '@mui/icons-material'
import InvoiceIcon from '@mui/icons-material/ReceiptLong'
import { Box, Button, Card, Stack } from '@mui/material'
import { ISingleDocument } from '@typings'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { OvFormProvider } from 'src/components/core/ov-form-provider/ov-form-provider'

import { UpdateInvoiceForm } from 'src/partials/index.partials'

export const QuotationDetailView = observer(() => {
  const { ui, quotation, shared, client } = useStore()
  const isMobile = useIsMobile()
  const [edit, setEdit] = useState(false)
  const [finalized, setFinalized] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams()

  const pageTitle = useMemo(
    () =>
      quotation.single.number
        ? DYNAMIC_LABELS.QUOTATION_NR(quotation?.single?.number)
        : LABELS.QUOTATION,
    [quotation?.single?.number]
  )

  const getDefaultValues = async () => {
    return await quotation.getSingle(parseInt(id ?? ''))
  }

  const form = useForm({
    defaultValues: getDefaultValues,
  })

  const handleBack = () => navigate('..')

  const handleSuccess = (newDocument: ISingleDocument) => {
    setEdit(false)
    form.reset(newDocument)
    ui.handleToaster('Wijzigingen opgeslagen', { type: 'success' })
  }

  const onSubmitCallback = async (values: FieldValues) => {
    await quotation
      .update(values)
      .then(res => {
        handleSuccess(res)
      })
      .catch(e => console.error(e))
  }

  const handleSubmit = () => form.handleSubmit(onSubmitCallback)()

  const handleCancel = () => {
    form.reset()
    setEdit(false)
  }

  const handleFinalize = async () => {
    await ui
      .confirm({
        title: 'Offerte definitief maken?',
        content:
          // eslint-disable-next-line max-len
          'Door akkoord te gaan maak je de offerte definitief. Je kan deze hierna niet meer wijzigen. Weet je zeker dat je de factuur definitief wilt maken?',
        withCancel: true,
      })
      .then(() => setFinalized(true))
      .catch(e => void 0)
  }

  useEffect(() => {
    quotation.getSingle(parseInt(id ?? ''))
    client.getAll()
    if (!shared.has_vat_codes) shared.getVatCodes()
  }, [])

  return (
    <OvFormProvider
      loading={quotation.loading}
      form={form}
      readOnly={!edit}
      onSubmit={handleSubmit}>
      <PageWrapper
        backAction={handleBack}
        pageTitle={pageTitle}
        actions={
          <Box
            display="grid"
            gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr' }}
            width={'100%'}
            gap={1}>
            {edit && (
              <Button
                variant="outlined"
                onClick={handleCancel}>
                Annuleren
              </Button>
            )}
            {!finalized && (
              <Button
                endIcon={edit ? <Save /> : <Edit />}
                variant={edit ? 'contained' : 'outlined'}
                onClick={edit ? handleSubmit : () => setEdit(true)}>
                {edit ? 'Offerte Opslaan' : 'Offerte bewerken'}
              </Button>
            )}
            {!edit && !finalized && (
              <Button
                variant="contained"
                endIcon={<Check />}
                onClick={handleFinalize}>
                Maak definitief
              </Button>
            )}
            {finalized && (
              <>
                <Button
                  variant="outlined"
                  endIcon={<Download />}>
                  Download
                </Button>
                <Button
                  variant="contained"
                  endIcon={<InvoiceIcon />}>
                  Maak offerte
                </Button>
              </>
            )}
          </Box>
        }>
        <UpdateInvoiceForm documentType="quotation" />
        {isMobile && (
          <Card
            elevation={3}
            sx={({ palette }) => ({
              position: 'fixed',
              left: 0,
              bottom: edit ? 56 : 0,
              right: 0,
              zIndex: 98,
              borderRadius: 0,
              p: 1,
              transition: 'bottom 0.3s ease',
            })}>
            <Stack
              direction="row"
              gap={1}
              justifyContent="center">
              <Button
                sx={{
                  flex: 1,
                }}
                variant="outlined"
                onClick={handleCancel}>
                Annuleren
              </Button>
              <Button
                sx={{
                  flex: 1,
                }}
                endIcon={<Save />}
                variant={'contained'}
                onClick={handleSubmit}>
                {'Offerte opslaan'}
              </Button>
            </Stack>
          </Card>
        )}
      </PageWrapper>
    </OvFormProvider>
  )
})
