import { PageWrapper } from '@components'
import { LABELS, URL_PATHS, WARNINGS } from '@constants'
import { useIsMobile } from '@hooks/useIsMobile'
import { useStore } from '@hooks/useStore'
import { Add } from '@mui/icons-material'
import { Button } from '@mui/material'
import { GridRow, GridRowParams } from '@mui/x-data-grid'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { OvDataGrid } from 'src/components/core/ov-data-grid/ov-data-grid'
import { POLICIES } from 'src/constants/policies.constants'
import { MobileDocumentRow } from 'src/partials/index.partials'

export const QuotationListView = observer(() => {
  const { quotation, policies } = useStore()
  const isMobile = useIsMobile()
  const navigate = useNavigate()

  const handleRowClick = (params: GridRowParams) => {
    if (!params.id) return
    const path = generatePath(URL_PATHS.QUOTE_DETAIL, { id: params.id })
    return navigate(path)
  }

  const handleNewClick = async () => {
    navigate(URL_PATHS.QUOTE_CREATE)
  }

  const canCreate = useMemo(() => {
    return policies.can(POLICIES.DOCUMENT.CREATE)
  }, [policies.current_policies])

  const CustomRow = useCallback(
    (props: any) =>
      isMobile ? (
        <MobileDocumentRow
          {...props.row}
          onRowClick={handleRowClick}
        />
      ) : (
        <GridRow {...props} />
      ),
    [isMobile]
  )

  return (
    <PageWrapper
      pageTitle={LABELS.QUOTES}
      allowed={policies.can(POLICIES.DOCUMENT.READ)}
      allowedMessage={WARNINGS.NOT_ALLOWED.NO_COMPANY}
      actions={
        <Button
          variant="contained"
          disabled={!canCreate}
          endIcon={<Add />}
          onClick={handleNewClick}>
          Nieuw
        </Button>
      }>
      <OvDataGrid
        onRowClick={handleRowClick}
        slots={{
          row: CustomRow,
        }}
        withToolBar
        store={quotation.data_grid}
        responsiveColumns
      />
    </PageWrapper>
  )
})
