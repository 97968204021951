import { KEYS, STORE_KEYS } from '@constants'
import { saveToStorage } from '@helpers/storage.helpers'
import { Store } from '@stores'
import { ICompany, ICompanyTask, ISingleCompany } from '@typings'
import { action, computed, makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import { IKvkSearchQueryParams } from 'src/typings/kvk.typings'

export class CompanyStore {
  [key: string]: any
  loading: boolean
  companies: ISingleCompany[]
  company: null | ISingleCompany
  selectedCompanyId: ISingleCompany['id'] | undefined
  tasks: ICompany['tasks']
  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.companies = [] as ISingleCompany[]
    this.company = null
    this.selected = undefined
    this.loading = true
    this.tasks = []
    this.params = {}
  }

  @computed
  get current_company_id(): ISingleCompany['id'] | undefined {
    return this.company?.id
  }

  @computed
  get current_company(): null | ISingleCompany {
    return this.company
  }

  @computed
  get has_company(): boolean {
    return !!this.company
  }

  @computed
  get has_companies(): boolean {
    return !!this.companies.length
  }

  @action
  setSelectedCompany = async (id: ISingleCompany['id']) => {
    this.set(STORE_KEYS.SELECTED, id)
    this.getSingle(id)
  }

  @action
  create = async (data: Partial<ISingleCompany>) => {
    this.set(STORE_KEYS.LOADING, true)

    try {
      const result = await this.store.api.company.createCompany(data)
      console.log('result: ', result)
      this.set(STORE_KEYS.COMPANY, result)
      saveToStorage(KEYS.HAS_COMPANY, true)
      return Promise.resolve(result)
    } catch (e) {
      console.log('error: ', e)
      return Promise.reject(e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  update = async (data: Partial<ISingleCompany>) => {
    this.set(STORE_KEYS.LOADING, true)

    try {
      const result = await this.store.api.company.update(this.current_company?.id, data)
      this.set(STORE_KEYS.COMPANY, result)
      return Promise.resolve(result)
    } catch (e) {
      console.log('error: ', e)
      return Promise.reject(e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  delete = async () => {
    this.set(STORE_KEYS.LOADING, true)
    if (!this.has_company) {
      toast('You have no company!')
      return null
    }
    try {
      console.log('this company id: ', this.company?.id)
      await this.store.api.company.delete(this.company?.id)
      this.set(STORE_KEYS.COMPANY, null)
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e)
    } finally {
      this.set(STORE_KEYS.LOADING, true)
    }
  }

  @action
  createTask = async (data: ICompanyTask) => {
    this.set(STORE_KEYS.LOADING, true)
    try {
      await this.store.api.company.createTask(data)
      await this.getAllTasks()
      this.store.ui.resetModal()
      return Promise.resolve(data.label)
    } catch (e) {
      console.log('e: ', e)
      return Promise.reject(e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  getAllTasks = async () => {
    this.set(STORE_KEYS.LOADING, true)
    try {
      const result = await this.store.api.company.getAllTasks()
      this.set(STORE_KEYS.TASKS, result)
    } catch (e) {
      console.log('error: ', e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  getSingle = async (id: ISingleCompany['id']) => {
    this.set(STORE_KEYS.LOADING, true)
    try {
      const result = await this.store.api.company.getSingle(id)
      this.set(STORE_KEYS.COMPANY, result)
      Promise.resolve(result)
    } catch (e) {
      console.log('error: ', e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  getMyCompany = async () => {
    this.set(STORE_KEYS.LOADING, true)
    try {
      const result = await this.store.api.company.getMyCompany()
      if (!result.length) {
        saveToStorage(KEYS.HAS_COMPANY, false)
        return
      }
      this.set(STORE_KEYS.COMPANY, result[0])
      saveToStorage(KEYS.HAS_COMPANY, true)
      return Promise.resolve(result[0])
    } catch (e) {
      console.log('error: ', e)
      return Promise.reject(e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  submitSearch = async (params: Partial<IKvkSearchQueryParams>) => {
    // this.set(STORE_KEYS.LOADING, true)
    try {
      const result = await this.store.api.kvk.searchCompany(params)

      // if (params.handelsnaam) {
      //   result.resultaten = KVK.resultaten.filter(item =>
      //     item.handelsnaam.includes(params.handelsnaam as string)
      //   )
      // }
      console.log('result: ', result)
      return result
    } catch (e) {
      console.error(e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  set = (target: string, value: unknown, save?: boolean): unknown => {
    if (!target) return
    this[target] = value
    if (save) saveToStorage(target, value)
  }
}
