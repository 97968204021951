import { URL_PATHS } from '@constants'
import { useIsMobile } from '@hooks/useIsMobile'
import { useStore } from '@hooks/useStore'
import { Close, GroupAdd, ReceiptLong, TableView } from '@mui/icons-material'
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { POLICIES } from 'src/constants/policies.constants'

export const OutvoiceQuidAddMenu = observer(() => {
  const { auth, policies } = useStore()
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  const willRender = () => {
    let result = false

    if (
      policies.can(POLICIES.CLIENT.CREATE) ||
      policies.can(POLICIES.DOCUMENT.CREATE)
    ) {
      result = true
    }

    return result
  }

  if (!auth.is_authenticated || isMobile || !willRender()) return null

  const MENU_ITEMS = [
    {
      key: 1,
      policy: POLICIES.DOCUMENT.CREATE,
      label: 'Maak factuur',
      icon: <ReceiptLong />,
      action: () => navigate(URL_PATHS.INVOICE_CREATE),
    },
    {
      key: 2,
      policy: POLICIES.DOCUMENT.CREATE,
      label: 'Maak offerte',
      icon: <TableView />,
      action: () => navigate(URL_PATHS.QUOTE_CREATE),
    },
    {
      key: 3,
      policy: POLICIES.CLIENT.CREATE,
      label: 'Voeg klant toe',
      icon: <GroupAdd />,
      action: () => navigate(URL_PATHS.CLIENT_CREATE),
    },
  ]

  const renderMenu = useMemo(
    () =>
      MENU_ITEMS.map(singleAction => {
        if (policies.can(singleAction.policy))
          return (
            <SpeedDialAction
              tooltipTitle={singleAction.label}
              tooltipPlacement="left"
              key={singleAction.key}
              onClick={singleAction.action}
              icon={singleAction.icon}
            />
          )
        return null
      }),
    []
  )

  return (
    <SpeedDial
      ariaLabel="Snelmenu"
      sx={{ position: 'fixed', bottom: 24, right: 24 }}
      icon={<SpeedDialIcon />}
      openIcon={<Close />}>
      {renderMenu}
    </SpeedDial>
  )
})
