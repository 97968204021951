import { ISingleCompany } from '@typings'

export const MY_COMPANY_DEFAULT_VALUES: Partial<ISingleCompany> = {
  name: '',
  coc: '',
  tax: null,
  hourly_rate: 0,
  invoice_expires_after: 30,
  quotation_expires_after: 30,
  tax_payable: false,
}
