import { PageWrapper } from '@components'
import { Box, Paper, Stack, Typography } from '@mui/material'

export const WelcomeOnboarding = () => {
  return (
    <>
      <Stack spacing={3}>
        <Stack spacing={1}>
          <Typography variant="h4">Een goed begin</Typography>
          <Typography>
            Gefeliciteerd! U heeft de eerste stap gezet naar moeiteloze en efficiënte bedrijfsadministratie. Met
            Outvoice heeft u alles wat u nodig heeft om uw financiën op orde te houden en naadloos samen te werken met
            uw accountant. In deze korte introductie nemen we u mee door de belangrijkste functies, zodat u direct aan
            de slag kunt.
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="h4">Overzichtelijke Administratie op Eén Plek</Typography>
          <Typography>
            Met Outvoice centraliseert u al uw financiële gegevens op één platform. Geen losse spreadsheets of stapels
            papieren facturen meer – alles is digitaal en overzichtelijk. In uw dashboard ziet u in één oogopslag uw
            inkomsten, uitgaven en openstaande facturen.
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="h4">Eenvoudige Facturaties</Typography>
          <Typography>Facturen maken en versturen was nog nooit zo makkelijk:</Typography>
          <ul>
            <li>✅ Maak binnen enkele seconden een professionele factuur.</li>
            <li>✅ Verstuur facturen direct naar uw klanten vanuit Outvoice. </li>
            <li>✅ Volg eenvoudig op welke facturen al betaald zijn en welke nog openstaan.</li>
          </ul>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="h4">Een goed begin</Typography>
          <Typography>
            Gefeliciteerd! U heeft de eerste stap gezet naar moeiteloze en efficiënte bedrijfsadministratie. Met
            Outvoice heeft u alles wat u nodig heeft om uw financiën op orde te houden en naadloos samen te werken met
            uw accountant. In deze korte introductie nemen we u mee door de belangrijkste functies, zodat u direct aan
            de slag kunt.
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="h4">Overzichtelijke Administratie op Eén Plek</Typography>
          <Typography>
            Met Outvoice centraliseert u al uw financiële gegevens op één platform. Geen losse spreadsheets of stapels
            papieren facturen meer – alles is digitaal en overzichtelijk. In uw dashboard ziet u in één oogopslag uw
            inkomsten, uitgaven en openstaande facturen.
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="h4">Eenvoudige Facturaties</Typography>
          <Typography>Facturen maken en versturen was nog nooit zo makkelijk:</Typography>
          <ul>
            <li>✅ Maak binnen enkele seconden een professionele factuur.</li>
            <li>✅ Verstuur facturen direct naar uw klanten vanuit Outvoice. </li>
            <li>✅ Volg eenvoudig op welke facturen al betaald zijn en welke nog openstaan.</li>
          </ul>
        </Stack>
      </Stack>
    </>
  )
}
